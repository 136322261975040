<template>
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <signature-pad class="bg-white signature" width="500px" height="500px" ref="signaturePad" />
      </div>
      <div class="col-12 text-center my-4">
        <button @click="onSave" class="btn btn-primary">Unterschrift bestätigen</button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import SignaturePad from 'vue-signature-pad';
import priceCalculator from '../service/price-calculator';

export default Vue.extend({
  name: 'signature',
  components: {
    SignaturePad,
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onSave() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (!isEmpty && typeof this.$route.params.id === 'string') {
        priceCalculator
          .sigature(this.$route.params.id, {
            image: data,
          })
          .then((result) => {
            this.$toasted.success('Signature saved successfully');
          });
      }

      this.$emit('on-digital-sign');
    },
  },
});
</script>
